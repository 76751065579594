import {
  type DisciplinePhaseManager,
  ShootingTypes,
  BowAnimationsNames,
  DrawTypes,
  MinigameVersionTypes,
  BlueBoxTextType
} from '../../types'
import {
  aimConfig,
  debugConfig,
  drawConfig,
  gameConfig,
  shootingConfig
} from '@/app/config'
import {
  cameraManager,
  CameraStates,
  MobileDetector,
  THREE,
  gsap,
  game
} from '@powerplay/core-minigames'
import { aimingDirectionManager } from './AimingDirectionManager'
import { player } from '@/app/entities/athlete/player'
import { ObjectWrapper } from '@/app/entities/objectWrapper/ObjectWrapper'
import { startPhaseStateManager } from '../StartPhase/StartPhaseStateManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { worldEnv } from '@/app/entities/env/WorldEnv'
import {
  actionButtonState,
  inputsState,
  releasePhaseState,
  tutorialState,
  uiState,
  windState
} from '@/stores'

/**
 * Trieda fazy pre mierenie
 */
export class AimPhase implements DisciplinePhaseManager {

  /** Kvalita inputu */
  public quality = 0

  /** Ci je aktivna faza */
  public isActive = false

  /** Pocitadlo frameov */
  private frameCounter = 0

  /** Aktualna pozicia ukazovatela na bare */
  private barMarkPosition = 0

  /** Aktualny smer ukazovatela na bare */
  private barDirection = 1

  /** wrapper pre pusku a kameru */
  public wrapper = new ObjectWrapper()

  /** Ci uz skoncila tato faza */
  private ended = false

  /** Originalna pozicia hraca kvoli resetu */
  private playerPositionOriginal = new THREE.Vector3()

  /** Originalna rotacia hraca kvoli resetu */
  private playerRotationOriginal = new THREE.Euler()

  /** kamera up vektor */
  private CAMERA_UP_VECTOR = new THREE.Vector3(0, 1, 0)

  /** vektor doprava */
  private RIGHT_VECTOR = new THREE.Vector3(0, 0, 1)

  /** ci sa podarilo zamknut kurzor */
  public isLocked = false

  /** ci ma byt locknute */
  private shouldBeLocked = false

  /**
   * Konstruktor
   * @param callbackEnd - callback na zavolanie po skonceni fazy
   */
  public constructor(private callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    this.shouldBeLocked = true
    this.tryToLockPointer()
    tutorialFlow.aimPhaseStared()
    uiState().blueBoxTextType = BlueBoxTextType.hidden
    console.warn('aim phase started')
    inputsState().disabled = false
    const touchStartInput = [MinigameVersionTypes.b, MinigameVersionTypes.c].includes(gameConfig.minigameVersionType)
    actionButtonState().isStart = touchStartInput

    if (drawConfig.type === DrawTypes.bar) aimingDirectionManager.calculateDeviation()

    aimingDirectionManager.setMouseStep()
    aimingDirectionManager.createTargetPoint('_orig')
    aimingDirectionManager.createTargetPoint()
    this.setCameraWrapper()
    this.setCameraRendering()
    this.wrapper.lookAt(aimingDirectionManager.aimingPoint.position)
    this.isActive = true

    if (shootingConfig.type === ShootingTypes.bar) {

      // nastartujeme bar - pricom dame random hodnoty
      this.barMarkPosition = THREE.MathUtils.randInt(0, shootingConfig.barType.barMaxValue)
      this.barDirection = Math.round(Math.random()) === 0 ? 1 : -1
      releasePhaseState().isActive = true

    }

    player.setHandsVisibility(true)
    player.setHairVisibility(false)
    aimingDirectionManager.aimingPoint.visible = aimConfig.debug.showAimingPoint
    aimingDirectionManager.targetPoint.visible = aimConfig.debug.showTargetPoint
    aimingDirectionManager.targetPointOriginal.visible = aimConfig.debug.showTargetPointOriginal

    // nastavime inu texturu pre digital numbers
    worldEnv.changeTextureDigitalDisplay(false)

  }

  /**
   * Pokusime sa locknut kurzor
   */
  public tryToLockPointer(): void {

    if (MobileDetector.isMobile() || !this.shouldBeLocked || this.isLocked || debugConfig.debugCamera) return

    console.log('try lock')

    const canvasEl = game.renderManager.getDomElement()
    if (!canvasEl.requestPointerLock) return

    document.addEventListener('pointerlockerror', this.lockError, false)

    canvasEl.requestPointerLock()
    aimingDirectionManager.update()

    document.addEventListener('pointerlockchange', this.lockChange.bind(this), false)

  }

  /**
   * Event pri zmene pointerlock
   */
  private lockChange(): void {

    const canvasEl = game.renderManager.getDomElement()
    if (document.pointerLockElement === canvasEl) {

      console.log('The pointer lock status is now locked')
      this.isLocked = true
      uiState().showEsc = true
      inputsState().exitPressed = false

    } else {

      console.log('The pointer lock status is now unlocked')
      this.isLocked = false
      uiState().showEsc = false
      if (this.shouldBeLocked) {

        inputsState().exitPressed = true

      }
      document.removeEventListener('pointerlockerror', this.lockError, false)
      document.removeEventListener('pointerlockchange', this.lockChange.bind(this), false)

    }

  }

  /**
   * Odomknutie pointera
   * @param openMenu - ci sa ma otvorit menu
   */
  public unlockPointer(openMenu = false): void {

    if (MobileDetector.isMobile() || !this.isLocked || !document.exitPointerLock) return

    console.log('unlock')
    this.shouldBeLocked = openMenu
    document.exitPointerLock()
    this.isLocked = false

  }

  /**
   * Riesenie erroru pri lockovani
   */
  private lockError(): void {

    console.log('Pointer sa nepodarilo locknut')
    this.isLocked = false

  }

  /**
   * Kontrola a riesenie inputov
   * @param isTouch - ci input je touch na mobile
   */
  public handleInputs(isTouch = false): void {

    if (!this.isActive) return

    if (MobileDetector.isMobile() && !isTouch) return
    if (shootingConfig.type === ShootingTypes.hold || this.frameCounter > 10) this.finishPhase(false)

  }

  /**
   * Spravovanie trasenia
   */
  private manageShake(): void {

    const { active, framesMax, framesMin } = aimConfig.shake

    if (!active) return

    let percent = (this.frameCounter - framesMin) / (framesMax - framesMin)
    if (percent > 1) percent = 1
    if (percent < 0) percent = 0
    aimingDirectionManager.setShakePercent(percent)

  }

  /**
   * dame kameru a pusku do wrappera
   */
  public setCameraWrapper(): void {

    const camera = cameraManager.getMainCamera()
    camera.up.set(0, 1, 0)

    cameraManager.setState(CameraStates.static)

    camera.position.set(0, 0, 0)
    camera.lookAt(this.RIGHT_VECTOR)

    this.wrapper.set(
      aimConfig.camera.wrapperPosition,
      camera,
      player.athleteObject
    )

    // musime si poznacit, aka bola pozicia a rotacia
    this.playerPositionOriginal = player.athleteObject.position.clone()
    this.playerRotationOriginal = player.athleteObject.rotation.clone()

    const { position, rotation } = aimConfig.camera.athlete
    player.athleteObject.position.set(position.x, position.y, position.z)
    player.athleteObject.rotation.set(rotation.x, rotation.y, rotation.z)

  }

  /**
   * nastavime vykreslovanie kamery
   */
  public setCameraRendering(): void {

    player.changeCameraRenderSettings(
      aimConfig.camera.near,
      aimConfig.camera.far,
      MobileDetector.isMobile() ? aimConfig.camera.fovMobile : aimConfig.camera.fovDesktop
    )

    cameraManager.getMainCamera().up = this.CAMERA_UP_VECTOR

  }

  /**
   * resetneme kameru na konci
   */
  private resetCameraRendering(): void {

    cameraManager.setState(CameraStates.discipline)
    player.changeCameraRenderSettings()

  }

  /**
   * Aktualizacia pozicie znacky
   */
  private updateMarkPosition(): void {

    if (shootingConfig.type !== ShootingTypes.bar) return

    const { addValuePerFrame, barMaxValue, barMinValue } = shootingConfig.barType

    // posuvame bar
    this.barMarkPosition += (addValuePerFrame * this.barDirection)
    if (this.barMarkPosition > barMaxValue) {

      this.barMarkPosition = barMaxValue
      this.barDirection *= -1

    }
    if (this.barMarkPosition < barMinValue) {

      this.barMarkPosition = barMinValue
      this.barDirection *= -1

    }

    releasePhaseState().markPosition = this.barMarkPosition

  }

  /**
   * Nastavenie kvality podla kliku
   */
  private setQuality(): void {

    if (shootingConfig.type !== ShootingTypes.bar) return

    const { barIdealValue, barMaxValue, barMinValue } = shootingConfig.barType

    this.quality = barMaxValue - (2 * Math.abs(this.barMarkPosition - barIdealValue))
    if (this.quality < barMinValue) this.quality = barMinValue
    if (this.quality > barMaxValue) this.quality = barMaxValue

    console.log(`Kvalita release fazy je: ${ this.quality}`)

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.frameCounter += 1

    // schovame kurzor
    if (document.body.style.cursor !== 'none') document.body.style.cursor = 'none'

    this.wrapper.lookAt(aimingDirectionManager.aimingPoint.position)
    aimingDirectionManager.update()

    this.manageShake()
    this.updateMarkPosition()

  }

  /**
   * Ukoncene fazy
   * @param forced - True, ak sa vynutil koniec a preskakuje sa faza
   */
  public finishPhase = (forced: boolean): void => {

    if (this.ended) return
    startPhaseStateManager.disableInputs()
    this.unlockPointer()

    windState().showWindCenter = false
    tutorialState().showInstructionBox = false
    this.ended = true
    this.isActive = false
    console.warn('aim phase ended')
    gsap.to({}, {
      onComplete: () => {

        releasePhaseState().isActive = false

      },
      duration: 2
    })

    if (forced) {

      this.quality = 0
      this.resetCameraAndWrapper()
      player.setHandsVisibility(false)
      player.setHairVisibility(true)
      player.bow.animationsManager.changeTo(BowAnimationsNames.idle)
      player.setGameCameraSettings()
      cameraManager.setState(CameraStates.discipline)
      return

    }

    // nastavime kvalitu
    this.setQuality()

    this.callbackEnd()

  }

  /**
   * Resetovanie veci s kamerou, wrapperom a pod
   */
  public resetCameraAndWrapper(): void {

    this.resetCameraRendering()
    this.wrapper.destroy()
    this.wrapper = new ObjectWrapper()

    player.athleteObject.position.set(
      this.playerPositionOriginal.x,
      this.playerPositionOriginal.y,
      this.playerPositionOriginal.z
    )

    player.athleteObject.rotation.set(
      this.playerRotationOriginal.x,
      this.playerRotationOriginal.y,
      this.playerRotationOriginal.z
    )

    // vratime naspat kurzor mysy
    document.body.style.cursor = 'auto'

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.quality = 0
    this.isActive = false
    this.frameCounter = 0
    this.barMarkPosition = 0
    this.barDirection = 1
    this.wrapper = new ObjectWrapper()
    this.ended = false
    this.playerPositionOriginal.set(0, 0, 0)
    this.playerRotationOriginal.set(0, 0, 0)
    aimingDirectionManager.reset()
    this.shouldBeLocked = false

  }

}

import {
  pathAssets,
  pathAssetsGlobal
} from '@/globals/globalvariables'
import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { ModelsNames } from '../types'

// Hlavny priecinok s modelmi
const modelsDir = `${pathAssets}/models/`
const modelsDirGlobal = `${pathAssetsGlobal}/models/`

/**
 * Konfig pre modely
 */
export const modelsConfig: LoaderDataTypes = {
  [ModelsNames.athlete]: {
    ext: 'glb',
    genderActive: true,
    dir: modelsDir,
    version: 240503,
    femaleVersion: 240503,
    mainMeshNames: [ModelsNames.athlete],
    opponentCheck: true
  },
  [ModelsNames.env]: {
    ext: 'glb',
    version: 231208,
    dir: modelsDir
  },
  [ModelsNames.envDynamic]: {
    ext: 'glb',
    version: 2312143,
    dir: modelsDir
  },
  [ModelsNames.flags]: {
    ext: 'glb',
    dir: modelsDir
  },
  [ModelsNames.skyboxBuildings]: {
    ext: 'glb',
    version: 241016,
    dir: modelsDirGlobal
  },
  // [ModelsNames.cameraBoxes]: {
  //   ext: 'glb',
  //   dir: modelsDir,
  //   version: 231208
  // }

}

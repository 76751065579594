<template>
  <div
    v-if="isActive"
    class="draw-bar"
  >
    <horizontal-bar-with-ideal
      :glow="false"
      :mark="drawPhaseState.markPosition"
      :gradient="barValues"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { HorizontalBarWithIdeal } from '@powerplay/core-minigames-ui-ssm'
import { drawConfig } from '@/app/config'
import { DrawTypes } from '@/app/types'
import { drawPhaseState } from '@/stores'

export default defineComponent({
  name: 'DrawBar',
  components: {
    HorizontalBarWithIdeal
  },
  data() {

    return {
      drawPhaseState: drawPhaseState(),
    }

  },
  computed: {
    isActive() {

      return drawConfig.type === DrawTypes.bar && drawPhaseState().isActive

    },
    barValues() {

      return drawConfig.barType.barColorLimits

    }
  }
})

</script>

<style lang="less" scoped>
.draw-bar {
  position: absolute;
  bottom: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
}
</style>

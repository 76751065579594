import {
  THREE,
  game,
  playersManager,
  PlayerSex,
  HairColorTypes,
  errorManager,
  type PlayerInfo,
  modes
} from '@powerplay/core-minigames'
import {
  gameConfig,
  modelsConfig
} from '../../../config'
import {
  MaterialsNames,
  ModelsNames
} from '../../../types'
import { Athlete } from '..'
import { materialsConfig } from '@/app/config/materialsConfig'
import { player } from '../player'

/**
 * Trieda pre hraca
 */
export class Opponent extends Athlete {

  /**
   * Zistenie, ci je super aktivny alebo nie
   * @returns True, ak je aktivny
   */
  public getActive(): boolean {

    return !modes.isTutorial() &&
      !modes.isTrainingMode() &&
      !modes.isDailyLeague() &&
      !modes.isTournament() &&
      playersManager.players.length > 1

  }

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    const sex = playersManager.getPlayerById(this.uuid)?.sex
    let objectForClone

    const meshAthleteName = modelsConfig[ModelsNames.athlete]?.mainMeshNames?.[0]
    if (!meshAthleteName) {

      throw new Error(errorManager.showBox('Mesh name for athlete was not defined'))

    }
    if (playersManager.getPlayer().sex !== sex) {

      objectForClone = game.getObject3D('athlete_opponent')

    }
    console.log('HGE', objectForClone, this.uuid, playersManager.getPlayer().sex, sex)
    const athleteObject = objectForClone ?
      game.cloneSkeleton(objectForClone) :
      game.cloneSkeleton(player.athleteObject)

    materialsConfig[ModelsNames.athlete]?.meshesArray?.forEach((meshName) => {

      const opponentMesh = athleteObject.getObjectByName(meshName) as THREE.Mesh
      if (!opponentMesh) return
      meshName += '_opponent_1'
      opponentMesh.name = meshName

      // musime nastavit material
      opponentMesh.material = game.materialsToUse.basic
        .get(MaterialsNames.athleteOpponent1) as THREE.MeshBasicMaterial

    })
    materialsConfig[MaterialsNames.athleteHair]?.meshesArray?.forEach((meshName) => {

      const opponentMesh = athleteObject.getObjectByName(meshName) as THREE.Mesh
      if (!opponentMesh) return
      meshName += '_opponent_1'
      opponentMesh.name = meshName

      // musime nastavit material
      opponentMesh.material = game.materialsToUse.basic
        .get(`${MaterialsNames.athleteHairOpponent1 }`) as THREE.MeshBasicMaterial

    })

    materialsConfig[MaterialsNames.athleteEquipment]?.meshesArray?.forEach((meshName) => {

      const opponentMesh = athleteObject.getObjectByName(meshName) as THREE.Mesh
      if (!opponentMesh) return
      meshName += '_opponent_1'
      opponentMesh.name = meshName

      // musime nastavit material
      opponentMesh.material = game.materialsToUse.basic
        .get(`${MaterialsNames.athleteEquipmentOpponent1 }`) as THREE.MeshBasicMaterial

    })

    return athleteObject

  }

  /**
   * Vytvorenie lyziara
   */
  public create(): void {

    console.log('vytvaram protihraca...')

    this.prefixSex = playersManager.getPlayerById(this.uuid)?.sex === PlayerSex.male ? '' : 'f_'

    const startPosition = new THREE.Vector3(-2.83, 0.05, 66.59)
    const startRotation = new THREE.Vector3(0, 0, 0)
    super.create('Opponent 1', startPosition, startRotation)

    // const bow = new Bow().create()
    // const arrow = new Arrow().create()
    // spravime zmeny s vlasmi a klobukmi + ruky schovame, ak nahodou boli
    this.setHairAndHat()
    this.setHandsVisibility(false)

  }

  /**
   * Vytvorenie luku a sipu
   */
  public createTools(): void {

    super.createTools()

    if (opponent.uuid !== '') opponent.arrow.setVisibility(false)
    const arrow = this.athleteObject.getObjectByName('athlete_arrow_2_opponent_1')
    if (arrow) arrow.visible = false
    const arrow2 = this.athleteObject.getObjectByName('athlete_opponent_arrow_2')
    if (arrow2) arrow2.visible = false

  }

  /**
   * Vyberie supera, ktory bude proti hracovi
   * @returns uuid
   */
  public chooseOpponentUuid(): string {

    const sortedPlayers = playersManager.getPlayersSortedByAttribute()

    const playerIndex = sortedPlayers.findIndex((p: PlayerInfo) => {

      return p.playable

    })

    if ((playerIndex + 1) % 2 === 0) {

      this.uuid = sortedPlayers[playerIndex - 1].uuid

    } else {

      this.uuid = sortedPlayers[playerIndex + 1].uuid

    }

    return this.uuid

  }

  /**
   * Nastavenie vlasov a klobuku
   */
  private setHairAndHat(): void {

    this.hair = this.athleteObject.getObjectByName(`${this.prefixSex}hair_low_opponent_1`) as THREE.Mesh
    this.hairTail = this.athleteObject.getObjectByName(`${this.prefixSex}tail_low_opponent_1`) as THREE.Mesh

    const athlete = playersManager.getPlayerById(this.uuid)
    console.log('ako?', athlete, this.hair, this.hairTail)
    if (!athlete) return

    const maxCountHairTypes = 3
    this.hairType = athlete.hair ?? Math.floor(Math.random() * maxCountHairTypes) + 1
    if (this.hairType > maxCountHairTypes) this.hairType = maxCountHairTypes

    const material = this.hair.material as THREE.MeshBasicMaterial
    const hairColor = athlete.hairColor ?? HairColorTypes.brown
    material.color = new THREE.Color(gameConfig.hairColors[HairColorTypes[hairColor]]).convertSRGBToLinear()

    this.setHairVisibility(true)

  }

  /**
   * Nastavenie viditelnosti pre vlasy a klobuk
   * @param visibility - Ci maju byt viditelne alebo nie
   */
  public setHairVisibility(visibility: boolean): void {

    const athlete = playersManager.getPlayerById(this.uuid)
    if (!athlete) return

    let hairTypesVisible = [1, 3, 6, 7]
    let hairTailTypesVisible = [3]

    if (athlete.sex === PlayerSex.female) {

      hairTypesVisible = [1, 2, 3, 5, 6, 7]
      hairTailTypesVisible = [2, 3, 5, 7]

    }

    this.hair.visible = hairTypesVisible.includes(this.hairType) && visibility
    this.hairTail.visible = hairTailTypesVisible.includes(this.hairType) && visibility

  }

  /**
   * Nastavenie viditelnosti samostanych ruk namiesto celeho tela
   * @param visible - Ci maju byt ruky viditelne
   */
  public setHandsVisibility(visible: boolean): void {

    const hands = this.athleteObject.getObjectByName('hands')
    if (hands) hands.visible = visible

    const body = this.athleteObject.getObjectByName(`${this.prefixSex}body_low_opponent_1`)
    if (body) body.visible = !visible

  }

}

export const opponent = new Opponent('')
import {
  CustomEvents,
  translations,
  CameraStates,
  game,
  modes,
  playersManager,
  trainingManager,
  hints,
  requestManager,
  audioManager,
  cameraManager,
  corePhasesManager
} from '@powerplay/core-minigames'
import {
  AudioGroups,
  AudioNames
} from '@/app/types'
import {
  loadingCircleState,
  loadingState,
  translationsSSM as t,
  tutorialCoreState
} from '@powerplay/core-minigames-ui-ssm'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { aimingDirectionManager } from '@/app/phases/AimPhase/AimingDirectionManager'
import { tableStandingsHelper } from './tableStandingsHelper'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { translatesReplacements } from '@/app/config'
import {
  blurState,
  instructionsState,
  tableState,
  tournamentState,
  trainingResultsState
} from '@/stores'
import { stateManager } from '@/app/StateManager'
import { endManager } from '@/app/EndManager'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initiateListeners = (vm: any) => {

  // Listener for language download
  window.addEventListener(CustomEvents.translations, () => {

    if (Number(import.meta.env.VITE_APP_LOCAL)) {

      translations.setTranslations(t, translatesReplacements)

    }

    vm.$i18n.setLocaleMessage('lang', translations.getTranslations())

    // musime zmenit hint v loadingu
    hints.setSpecificHintsCountForDiscipline(3)
    const hint = hints.getHintText(requestManager.disciplineID)

    loadingState().$patch({
      showLoading: true,
      hintText: vm.$t(hint)
    })

  })

  // Listener for game start event
  window.addEventListener(CustomEvents.gameStart, () => {

    loadingState().showLoading = false
    audioManager.play(AudioNames.audienceQuiet)

  })

  // Listener for game start instruction phase
  window.addEventListener(CustomEvents.startInstructionsPhase, () => {

    instructionsState().$patch({
      showInstructions: true,
      showButton: false
    })
    cameraManager.setState(CameraStates.table)
    cameraManager.playTween(true)
    game.renderScene()

  })

  // Listener pre tutorial Typewrite
  window.addEventListener(CustomEvents.typeWrite, () => {

    if (!modes.isTutorial()) return

    tutorialCoreState().typeWriter = false

  })

  // Listener pre tutorial game event
  window.addEventListener(CustomEvents.tutorialGameEvent, () => {

    if (!modes.isTutorial()) return

    tutorialFlow.checkInput()

  })

  // Listener for game intro start event
  window.addEventListener(CustomEvents.startIntroPhase, () => {

    if (modes.isTutorial()) return
    tableState().$patch({
      competitionText: modes.isTrainingMode() ?
        vm.$t('level').replace('%s', trainingManager.level) :
        `tableText${modes.mode}`,
      showTable: true
    })

    if (!modes.isTutorial() && !modes.isTrainingMode()) {

      if (!audioManager.isAudioGroupPlaying(AudioGroups.commentators)) {

        audioManager.play(AudioNames.commentatorIntro)

      }

    }

  })

  // Listener for game start listing phase
  window.addEventListener(CustomEvents.startStartListPhase, () => {

    if (modes.isTutorial()) return

    blurState().$patch({
      isActive: true,
      isTable: true
    })

    const data = playersManager.getDataForTable()
    console.log(data)
    tableState().$patch({
      dataTable: data,
      resultText: 'startList',
      isStartList: true,
      activeState: true
    })

  })

  // Listener for game start discipline phase
  window.addEventListener(CustomEvents.startDisciplinePhase, () => {

    if (modes.isTutorial()) return

    blurState().$patch({
      isActive: false,
      isTable: false
    })

    tableState().$patch({
      showTable: false,
      activeState: false,
      isStartList: false
    })

    // v turnajoch mozeme dostat -1 hodnoty
    if (modes.isTournament() && playersManager.players[0].resultsArr && endManager.resultsCount === 0) {

      for (let i = 0; i < 6; i += 1) {

        playersManager.players[0].resultsArr[i] = { main: 0 }

      }

    }

  })

  // Listener for game start provisional results phase
  window.addEventListener(CustomEvents.startProvisionalResultsPhase, () => {

    if (modes.isTutorial()) return

    blurState().$patch({
      isActive: true,
      isTable: true
    })
    playersManager.setStandings(
      2,
      modes.isTournament() && corePhasesManager.disciplineActualAttempt <= 1,
      modes.isTournament() && corePhasesManager.disciplineActualAttempt <= 1
    )

    const dataStandings = tableStandingsHelper.getDataForArcheryTableWith10sAndX()
    console.log(dataStandings)
    // musime si zaznacit najlepsi vysledok, aby sme to potom mohli zobrazit
    tournamentState().bestResult = dataStandings[0].result ?? '-'
    if (tournamentState().bestResult === '') tournamentState().bestResult = '-'

    tableState().$patch({
      dataTable: dataStandings,
      showTable: true,
      activeState: true,
      resultText: 'provisionalResults'
    })
    console.warn(tableState().dataTable)

    // pre turnaje v prvej tabulke nesmieme riesit zvuky
    if (corePhasesManager.disciplineActualAttempt > 1) {

      if (!audioManager.isAudioGroupPlaying(AudioGroups.commentators)) {


        audioManager.play(AudioNames.commentatorProvisionalStandings)

      }

    }

    audioManager.resetSpriteCache(AudioNames.commentatorPoints8)
    audioManager.resetSpriteCache(AudioNames.commentatorPoints9)
    audioManager.resetSpriteCache(AudioNames.commentatorPoints10)
    audioManager.resetSpriteCache(AudioNames.commentatorRobinHood)

  })

  // Listener for game start final standings phase
  window.addEventListener(CustomEvents.startFinalStandingsPhase, () => {

    if (modes.isTutorial()) return

    if (!audioManager.isAudioGroupPlaying(AudioGroups.commentators)) {

      audioManager.play(AudioNames.commentatorFinalResults)

    }

    if (modes.isTournament() && !disciplinePhasesManager.tournamentDataSet) return

    blurState().$patch({
      isActive: true,
      isTable: true
    })

    if (modes.isTournament()) playersManager.setStandings()

    tableState().$patch({
      dataTable: tableStandingsHelper.getDataForArcheryTableWith10sAndX(),
      showTable: true,
      activeState: true,
      resultText: modes.isTournament() || modes.isDailyLeague() ? 'provisionalResults' : 'finalStandings',
      competitionText: modes.isTrainingMode() ?
        vm.$t('level').replace('%s', trainingManager.level) :
        `tableText${modes.mode}`
    })


  })

  // Listener for game start final training phase
  window.addEventListener(CustomEvents.startFinalTrainingPhase, () => {

    if (modes.isTutorial()) return
    trainingResultsState().showResults = true

  })

  // Listener pre to, aby sme vedeli, ze su vsetky konecne requesty zbehnute
  window.addEventListener(CustomEvents.allRequestDoneOnEnd, () => {

    if (modes.isTournament() && Number(import.meta.env.VITE_APP_LOCAL) === 1) return

    stateManager.afterAllRequestsDone()

  })

  /** Listener pre zobrazenie loading circle po kliknuti na final redirect */
  window.addEventListener(CustomEvents.finalRedirectDone, () => {

    console.log('mal by ist redirect loading')
    loadingCircleState().isActive = true

  })

  window.addEventListener('mousemove', (e: MouseEvent) => {

    if (disciplinePhasesManager.phaseAim && disciplinePhasesManager.phaseAim.isLocked) {

      aimingDirectionManager.moveAimingPoint(
        e.movementX * aimingDirectionManager.mouseStep.x,
        -e.movementY * aimingDirectionManager.mouseStep.y,
        true
      )

    } else {

      aimingDirectionManager.actualMousePosition.set(e.clientX, e.clientY)

    }

  })

}

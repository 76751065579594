<template>
  <div
    v-if="tableState.showTable"
    class="positioner-table"
  >
    <power-table
      :row-data="tableState.dataTable"
      :header-props="headerProps()"
      :active="active"
      :is-training="isTraining"
      :is-start-list="tableState.isStartList"
      :show-discipline-icon="showDisciplineIcon"
      :show-ranking-points-ssm="showRankingPoints"
      :is-p-b="isPB"
      class="transform-origin-top-center"
      @mousedown.prevent="oneClick"
      @touchstart.prevent="oneClick"
    />
  </div>
</template>

<script lang="ts">
import {
  requestManager,
  modes,
  playersManager,
  type PlayerInfoForTable
} from '@powerplay/core-minigames'
import { PowerTable } from '@powerplay/core-minigames-ui-ssm'
import { pathAssetsCoreUi } from '@/globals/globalvariables'
import {
  tableState,
  type TableState
} from '@/stores'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DataTable',
  components: {
    PowerTable
  },
  data() {

    return {
      firstClick: false,
      active: false,
      isPB: false,
      tableState: tableState()
    }

  },
  computed: {
    isTraining() {

      return modes.isTrainingMode()

    },
    showDisciplineIcon() {

      return modes.isBossCompetition()

    },
    showRankingPoints() {

      return !modes.isBossCompetition() && !modes.isEventBossFight()

    }
  },
  watch: {
    tableState: {
      handler(value: TableState) {

        console.log(value)
        setTimeout(() => {

          this.active = value.activeState

        }, 10)

      },
      deep: true
    },
  },
  created() {

    document.addEventListener('keypress', () => {

      this.active = !this.active

    })

  },
  methods: {
    oneClick(event: Event) {

      if (tableState().activeState) event.stopPropagation()

    },
    headerProps() {

      const personalBest = playersManager.getPlayer().personalBest
      const result = Number(playersManager.getStandings().find((athlete: PlayerInfoForTable) => {

        return athlete.playable

      })?.result)
      let personalBestValue = personalBest

      let headerTableText = {}
      if (tableState().resultText !== 'startList') {


        this.isPB = personalBest < result
        personalBestValue = personalBest < result ? result : personalBest
        headerTableText = {
          col1: '10s',
          col2: 'Xs',
          col3: this.$t('points')
        }
        if (modes.isTournament()) {

          headerTableText = {
            col1: '',
            col2: '',
            col3: this.$t('points')
          }

        }

      }

      const pbObject = {
        showNew: this.isPB,
        value: String(personalBestValue)
      }
      if (modes.isTournament()) {

        pbObject.showNew = false
        pbObject.value = ''

      }
      return {
        discipline: {
          name: this.$t(`disciplineName${requestManager.disciplineID}`),
          image: `${pathAssetsCoreUi}/table/attribute-icon/disc-ico-light-02.svg`
        },
        competitonType: this.$t(tableState().competitionText),
        resultType: this.$t(tableState().resultText),
        headerTableText,
        personalBest: pbObject,
      }

    }
  }

})

</script>

<style lang="less">
.positioner-table {
  position: absolute;
  top: 13%;
  left: 50%;
  transform: translateX(-50%);

  .transform-origin-top-center {
      transform-origin: top center;
  }

  .time {
    .icon-point {
      margin-bottom: 10px;
    }
  }
}
</style>

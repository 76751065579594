import {
  modes,
  playersManager,
  type PlayerInfoForTable
} from '@powerplay/core-minigames'

/**
 * Helper, ktory pripravuje data do tabulky pre archery
 */
export const tableStandingsHelper = {
  getDataForArcheryTableWith10sAndX() {

    const dataForTable = JSON.parse(JSON.stringify(playersManager.getStandings())) as PlayerInfoForTable[]
    if (modes.isTournament()) {

      dataForTable.forEach(data => {

        data.results = []

      })
      return dataForTable

    }

    dataForTable.forEach(data => {

      let tens = 0
      let xs = 0
      data.results?.forEach(result => {

        const resultSplit = result.split(',').map(Number)
        xs += resultSplit[1] || 0
        tens += resultSplit[0] || 0

      })

      data.results = [String(tens), String(xs)]
      if (data.result === '') data.results = ['', '']

    })
    return dataForTable

  }
}
<template>
  <div class="wind-component-wrapper">
    <div
      class="wind-component"
      :style="[transformCoef, {
        marginRight: isMobile() ? '350px' : '300px'}]"
    >
      <div class="wind-component-value">
        <span
          class="wind-arrow"
          :class="[isRed ? 'red-arrow' : 'green-arrow']"
          :style="rotateStyle"
        />
        <span class="wind-text">
          {{ windValue }} {{ windUnit }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { WindowAspect } from '@powerplay/core-minigames-ui-ssm'

export default defineComponent({
  name: 'WindComponent',
  mixins: [WindowAspect],
  props: {
    rotateValue: {
      type: Number,
      default: 0
    },
    isRed: {
      type: Boolean,
      default: false
    },
    windValue: {
      type: String || Number,
      default: '0'
    },
    windUnit: {
      type: String,
      default: 'm/s'
    },
    isScaled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    transformCoef() {

      let scale = 1
      if (this.isScaled) {

        scale = this.scaleCoef

      }
      return {
        transform: `scale( ${scale} )`
      }

    },
    rotateStyle() {

      return {
        transform: `rotate(${this.rotateValue}deg)`
      }

    }
  }
})
/* eslint-disable max-len */
</script>

<style lang="less">
.wind-component-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .wind-component {
    width: 190px;
    height: 44px;
    background-image: linear-gradient(to right, rgba(74, 75, 117, 0) 0%, rgba(74, 75, 117, 0.75) 20%, rgba(74, 75, 117, 0.75) 80%, rgba(74, 75, 117, 0) 100%);
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 300px;
    transform-origin: right center;

    .wind-component-value {  font-size: 28px;
        font-weight: bold;
        font-stretch: condensed;
        text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.71);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        gap: 5px;

        .wind-arrow {
          width: 32px;
          height: 26px;
          display: inline-block;
        }
        .red-arrow {
          background: url("https://appspowerplaymanager.vshcdn.net/images/minigame/core-ui/wind/arrow-left-red.png") center;
        }
        .green-arrow {
          background: url("https://appspowerplaymanager.vshcdn.net/images/minigame/core-ui/wind/arrow-left-green.png") center;
        }

        .wind-text {
          width: 90px;
          display: flex;
          justify-content: flex-end;
          white-space: nowrap;
        }
    }
  }
}
</style>

import { defineStore } from 'pinia'

export interface BlurState {
  isActive: boolean,
  isTable: boolean,
  isToggle: boolean
}

const initialState = (): BlurState => ({
  isActive: false,
  isTable: false,
  isToggle: false
})

export const blurState = defineStore('blurState', {
  state: initialState,
})


import { defineStore } from 'pinia'
import { Direction } from '@/app/types'

export interface WindState {
  value: number,
  direction: Direction,
  showWind: boolean,
  showWindCenter: boolean,
  showCountdown: boolean
}

const initialState = (): WindState => ({
  value: 0,
  direction: Direction.N,
  showWind: false,
  showWindCenter: false,
  showCountdown: false
})

export const windState = defineStore('windState', {
  state: initialState,
})

import { gameConfig } from '@/app/config'
import {
  MinigameVersionTypes,
  type DisplayMessage
} from '@/app/types'
import {
  actionButtonState,
  inputsState,
  movementState,
  startMessageState,
  textMessageState,
  uiState
} from '@/stores'
import { modes } from '@powerplay/core-minigames'

export class StartPhaseStateManager {

  private textMessagesLinesFinishedEmits = [false, false]

  public disableInputs(): void {

    inputsState().disabled = true
    actionButtonState().disabled = true

  }

  public hideButtons(): void {

    actionButtonState().$patch({
      isStart: false,
      disabled: false
    })
    inputsState().$patch({
      isVisible: false,
      disabled: true
    })

  }

  public enableInputs(disabled = false): void {

    const touchStartInput = [MinigameVersionTypes.b].includes(gameConfig.minigameVersionType)
    actionButtonState().$patch({
      isStart: touchStartInput,
      disabled: !disabled
    })
    inputsState().$patch({
      isVisible: true,
      disabled
    })

  }

  public showStartMessageInUI(message: DisplayMessage): void {

    startMessageState().$patch({
      showMessage: true,
      messageText: message.message,
      messageColor: message.color
    })

  }

  public postIntroUiState(): void {

    uiState().$patch({
      showTimeKeeper: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })
    movementState().onlyHorizontal = true
    // inputsState().disabled = false

  }

  public finishPhaseUiState(): void {

    uiState().$patch({
      showTimeKeeper: true,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })

  }

  public enableStartInputs(): void {

    console.log('enable start inputs')
    // inputsState().disabled = false
    actionButtonState().isStart = true
    actionButtonState().disabled = false

  }

  public disableStartMessageInUI(message: DisplayMessage): void {

    startMessageState().$patch({
      showMessage: false,
      messageText: message.message,
      messageColor: message.color
    })

  }

  /**
   * Schovanie text message hlasky
   * @param lineNumber - Cislo riadku, ktore malo emit konca
   */
  public hideTextMessage(lineNumber: number): void {

    this.textMessagesLinesFinishedEmits[lineNumber - 1] = true

    if (this.textMessagesLinesFinishedEmits[0] && this.textMessagesLinesFinishedEmits[1]) this.hideAllTextMessages()

  }

  /**
   * Schovanie vsetkych message hlasok
   */
  public hideAllTextMessages(): void {

    textMessageState().$patch({
      showFirstLine: false,
      showSecondLine: false,
      firstLineText: '',
      firstLineTextType: 0,
      secondLineTextType: 0,
      secondLineLeftNumber: 0,
      showMessage: false,
      showType: 0
    })

  }

  /**
   * Resetovanie text message dokoncenych emitov
   * @param activeFirst - ci je aktivny prvy riadok
   * @param activeSecond - ci je aktivny druhy riadok
   */
  public resetTextMessageFinishedEmits(activeFirst: boolean, activeSecond: boolean): void {

    this.hideAllTextMessages()
    this.textMessagesLinesFinishedEmits = [!activeFirst, !activeSecond]

  }

}

export const startPhaseStateManager = new StartPhaseStateManager()

import { defineStore } from 'pinia'

export interface DrawPhaseState {
  isActive: boolean,
  markPosition: number
}

const initialState = (): DrawPhaseState=> ({
  isActive: false,
  markPosition: 0
})

export const drawPhaseState = defineStore('drawPhaseState', {
  state: initialState,
})

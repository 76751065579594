<template>
  <div
    class="info-component"
    :class="[isMobile() ? 'mobile-info-component' : '']"
  >
    <info-box
      v-if="tableState.showTable"
    />
  </div>
  <div
    class="info-component-bluebox"
    :class="[isMobile() ? 'mobile-info-component' : '']"
  >
    <blue-box
      v-if="uiState.blueBoxTextType >= 0"
      :side="'bottom'"
      show-border
      :style="{left: '50%', width: '610px', height: '67px',position: 'unset'}"
    >
      <div
        v-if="isMobile()"
        class="blue-box-content-mobile"
      >
        {{ $t('tapAndHold') }}
      </div>
      <div
        v-else
        class="blue-box-content-desktop"
      >
        <span class="left">{{ getLeftTextDesktop }}</span>
        <img
          src="https://appspowerplaymanager.vshcdn.net/images/minigame/core-ui/icons/ico-mouse.png"
        >
        <span class="right">{{ getRightTextDesktop }}</span>
      </div>
    </blue-box>
  </div>
</template>

<script lang="ts">
import {
  BlueBox,
  InfoBox,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'
import {
  tableState,
  uiState
} from '@/stores'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoComponent',
  components: {
    InfoBox,
    BlueBox
  },
  mixins: [WindowAspect],
  data() {

    return {
      tableState: tableState(),
      uiState: uiState(),
    }

  },
  computed: {
    getLeftTextDesktop() {

      let text = ''
      if (this.isMobile()) return text

      // String to find
      const searchString = '[button]'
      const s = this.$t('pressAndHold')

      const c = s.indexOf(searchString)
      if (c >= 0) {

        text = s.substring(0, c)

      }
      console.log(s, c, text)

      return text

    },
    getRightTextDesktop() {

      let text = ''
      if (this.isMobile()) return text

      // String to find
      const searchString = '[button]'
      const s = this.$t('pressAndHold')

      const c = s.indexOf(searchString)
      if (c >= 0) {

        text = s.substring(c + searchString.length)

      }

      return text

    },
  },
})

</script>

<style lang="less" scoped>
.info-component {
   position: absolute;
   bottom: .5rem;
   width: 100%;
   display: flex;
}
.info-component-bluebox {
   position: absolute;
   bottom: .5rem;
   width: 100%;
   display: flex;
   justify-content: center;

   &.mobile-info-component {
      bottom: .5rem;
   }

   .blue-box-content-mobile {
    font-size: 32px;
    font-weight: normal;
    color: #fff;
   }

  .blue-box-content-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    font-size: 32px;
    font-weight: normal;
    color: #fff;
  }
}
</style>

import {
  gsap,
  CameraStates,
  corePhasesManager,
  modes,
  cameraManager,
  playersManager,
  audioManager,
  fpsManager
} from '@powerplay/core-minigames'
import {
  PlayerStates,
  type DisciplinePhaseManager,
  BlueBoxTextType,
  PlayerTypes,
  PlayerAnimationsNames,
  AudioNames,
  AudioGroups
} from '../../types'
import { player } from '../../entities/athlete/player'
import { gameConfig } from '../../config'
import { startPhaseStateManager } from './StartPhaseStateManager'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { tutorialUIChange } from '@/app/modes/tutorial/TutorialUIChange'
import { opponent } from '@/app/entities/athlete/opponent/Opponent'
import { scoreDisplayPanel } from '@/app/entities/env/ScoreDisplayPanel'
import {
  inputsState,
  startPhaseState,
  textMessageState,
  uiState
} from '@/stores'
import { miniTableState } from '@/stores/miniTableState'

/**
 * Trieda pre startovaciu fazu
 */
export class StartPhaseManager implements DisciplinePhaseManager {

  /** ci sa deje nieco skipnutelne */
  private skippable = true

  /** ci uz je mozne odstartovat */
  public startable = false

  /** ci uz zobrazit ui player-info-avatar */
  private showName = false

  /** ci faza skoncila */
  public ended = false

  /** hodnota na ktorej stlacil */
  public clickedPower = 0

  /** Pocet frameov od zaciatku fazy */
  private framesInPhase = 0

  /** tween na skrytie odrazovej hlasky */
  public startingMessageTween?: gsap.core.Tween

  /** Tween pre dalsi animacny stav */
  private tweenForNextAnimationState?: gsap.core.Tween

  /** Premenna pre kameru */
  private cameraInPostIntroState = false

  /** zobrazenie bielej ziary */
  private showShine = false

  /**
   * Konstruktor
   * @param callbackEnd - callback na zavolanie po skonceni fazy
   */
  public constructor(private callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase(): void {

    this.storeState()

  }

  /**
   * Zacatie fazy
   */
  public startPhase(): void {

    fpsManager.startCounting()

    if (this.ended) {

      this.reset()
      this.afterCameraDisciplineIntro()
      return

    }

    console.warn('starting start phase')
    audioManager.play(AudioNames.cheerClap)

    // Zobrazit meno hraca v UI
    this.showName = !modes.isTutorial()
    this.setCameraForDisciplineIntro()

  }

  /**
   * Nastavenie kamery pre intro
   */
  private setCameraForDisciplineIntro(): void {

    if (gameConfig.cameraConfig.enabled) {

      player.setGameCameraSettings(undefined, true)

    }

    if (modes.isTutorial()) {

      this.afterCameraDisciplineIntro()
      tutorialFlow.init()
      tutorialUIChange.init()
      return

    }

    if (!disciplinePhasesManager.isFirstAttemptInGroup()) {

      this.afterCameraDisciplineIntro()
      return

    }

    if (!modes.isTrainingMode()) {

      player.bow.setVisibility(false)
      player.arrow.setVisibility(false)
      player.setState(PlayerStates.prepare)

    }
    if (opponent.uuid != '') {

      opponent.bow.setVisibility(false)
      opponent.arrow.setVisibility(false)
      opponent.setState(PlayerStates.prepare)

    }

    cameraManager.setState(CameraStates.disciplineIntro)
    cameraManager.playTween(false, this.afterCameraDisciplineIntro)

  }

  /** pomocna metoda pre animacie */
  public getCameraInPostIntroState(): boolean {

    return this.cameraInPostIntroState

  }

  /**
   * Spravenie veci po konci disciplinoveho intra
   */
  private afterCameraDisciplineIntro = (): void => {

    this.skippable = false
    this.cameraInPostIntroState = true
    this.showArrowText()
    let messageType = BlueBoxTextType.draw
    if (modes.isTutorial() && corePhasesManager.disciplineActualAttempt === 1) {

      messageType = BlueBoxTextType.hidden

    }
    uiState().blueBoxTextType = messageType

    if (this.tweenForNextAnimationState) this.tweenForNextAnimationState.kill()
    player.bow.setVisibility(true)
    player.arrow.setVisibility(true)
    if (opponent.uuid != '') {

      opponent.bow.setVisibility(true)
      opponent.arrow.setVisibility(true)
      opponent.animationsManager.changeTo(PlayerAnimationsNames.idle1)

    }

    // Zrusit zobrazenie mena hraca v UI
    this.showName = false
    cameraManager.setState(CameraStates.discipline)
    cameraManager.changeRenderSettings(undefined, undefined, 73.7 - 35)

    startPhaseStateManager.postIntroUiState()

    if (
      disciplinePhasesManager.isFirstAttemptInGroup() &&
      !(modes.isTrainingMode() && corePhasesManager.disciplineActualAttempt !== 1)
    ) {

      player.setState(PlayerStates.loading)

    }


    if (modes.isTutorial() && corePhasesManager.disciplineActualAttempt === 1) return

    // startPhaseStateManager.showStartMessageInUI({ message: 'ON YOUR MARKS', color: 1 })
    console.log('READY')
    this.storeState()

    this.finishPhase()

  }

  /**
   * Zobrazenie arrow [number] hlasky
   */
  private showArrowText(): void {

    const lastAttempt = corePhasesManager.disciplineActualAttempt === corePhasesManager.disciplineAttemptsCount
    const showFirstLine = false
    const showSecondLine = !modes.isTutorial()
    startPhaseStateManager.resetTextMessageFinishedEmits(showFirstLine, showSecondLine)

    textMessageState().$patch({
      showFirstLine: showFirstLine,
      showSecondLine: showSecondLine,
      firstLineText: '',
      firstLineTextType: 1,
      secondLineTextType: lastAttempt ? 3 : 2,
      secondLineLeftNumber: corePhasesManager.disciplineActualAttempt,
      showMessage: true,
      showType: 3
    })
    gsap.to({}, {
      onComplete: () => {

        // startPhaseStateManager.hideTextMessage()
        miniTableState().$patch({
          rowsData: [],
          show: false
        })

      },
      duration: 2
    })

  }

  /**
   * Inputy pri starte
   */
  public handleInputs(): void {

    // skip intra
    if (this.skippable && this.framesInPhase > 10) {

      console.log('skippped')
      this.skippable = false
      cameraManager.skipTween()
      this.framesInPhase = 0

    }

  }

  /**
   * Update kazdy frame
   */
  public update(): void {

    if (!this.ended) this.framesInPhase++

    this.storeState()

  }

  /**
   * Ukoncene fazy
   */
  public finishPhase(): void {

    if (!this.ended) {

      console.warn('start phase ended')
      // this.decreaseAudienceNoise()

      this.ended = true
      this.callbackEnd()
      player.start()
      if (opponent.uuid !== '') {

        const pointsSoFarOpponent = playersManager.getCountedResultsMainSoFar(opponent.uuid)
        scoreDisplayPanel.setNumberOnPanel(pointsSoFarOpponent, PlayerTypes.opponent)

      }

      this.storeState()

      startPhaseStateManager.finishPhaseUiState()
      // startPhaseStateManager.disableInputs()

      if (corePhasesManager.disciplineActualAttempt === 1) {

        audioManager.stopAudioByName(AudioNames.audienceQuiet)
        if (!audioManager.isAudioGroupPlaying(AudioGroups.commentators) && !modes.isTrainingMode()) {

          audioManager.play(AudioNames.commentatorBefore1stShot)

        }

      }

    }

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * UI update
   */
  private storeState(): void {

    startPhaseState().$patch({
      showName: this.showName,
      showPhase: !this.ended,
      attempt: corePhasesManager.disciplineActualAttempt,
      showShine: this.showShine
    })

  }

  /**
   * reset fazy
   */
  public reset(): void {

    inputsState().disabled = true
    this.skippable = true
    this.startable = false
    this.showName = false
    this.ended = false
    this.clickedPower = 0
    this.framesInPhase = 0
    this.showShine = false
    this.startingMessageTween = undefined
    this.tweenForNextAnimationState = undefined
    this.cameraInPostIntroState = false
    this.storeState()

    player.arrow.reset()

  }

}

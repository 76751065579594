import { defineStore } from 'pinia'

export interface TextMessageState {
  showFirstLine: boolean,
  showSecondLine: boolean,
  firstLineText: string,
  firstLineTextType: number,
  secondLineTextType: number,
  secondLineLeftNumber: number,
  showMessage: boolean,
  showType: number
}

const initialState = (): TextMessageState => ({
  showFirstLine: false,
  showSecondLine: false,
  firstLineText: '',
  firstLineTextType: 0,
  secondLineTextType: 0,
  secondLineLeftNumber: 0,
  showMessage: false,
  showType: 0
})

export const textMessageState = defineStore('textMessageState', {
  state: initialState,
})

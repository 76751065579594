<template>
  <countdown-component
    v-if="countdownState.isEnabled"
    :to-animate="countdownState.text"
    :duration="countdownState.duration"
    :header-text="$t(countdownState.headerText)"
    :show-big="countdownState.showBig"
    :show-small="countdownState.showSmall"
    :show-header="countdownState.showHeader"
    :left-start="countdownState.leftStart"
  />
</template>

<script lang="ts">

import { CountdownComponent } from '@powerplay/core-minigames-ui-ssm'
import { countdownState } from '@/stores'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CountdownComponentWrapper',
  components: {
    CountdownComponent
  },
  data() {

    return {
      countdownState: countdownState()
    }

  }
})

</script>

<style scoped lang="less">

    .countdown-container {
        z-index: 1;
    }

</style>

import { shootingConfig } from './config'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import {
  BlueBoxTextType,
  DisciplinePhases
} from './types'
import {
  corePhasesManager,
  fpsManager,
  gsap,
  modes,
  playersManager
} from '@powerplay/core-minigames'
import { startPhaseStateManager } from './phases/StartPhase/StartPhaseStateManager'
import { StateManager } from './StateManager'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { trainingTasks } from './modes/training'
import type { DrawPhaseWithHold } from './phases/DrawPhase/DrawPhaseWithHold'
import {
  actionButtonState,
  startPhaseState,
  textMessageState,
  uiState,
  windState
} from '@/stores'
import { miniTableState } from '@/stores/miniTableState'
import { endManager } from './EndManager'

/**
 * Trieda pre casovy limit
 */
export class TimeLimitManager {

  /** Pocitadlo frameov pre casovy limit */
  private frameCounter = 0

  /** Ci je aktivny */
  private isActive = false

  /** ci delay na out of time skoncil */
  private outOfTimeDelayEnded = false

  /** delay na out of time */
  private outOfTimeDelayActive = false

  /**
   * Nastavenie aktivnosti casoveho limitu
   * @param active - Ci sa ma aktivovat alebo deaktivovat
   */
  public setActive(active: boolean): void {

    this.isActive = active

  }

  /**
   * Skontrolovanie casoveho limitu
   * @returns Ci uz skoncil casovy limit
   */
  public check(): boolean {

    if (!this.isActive) return false

    this.frameCounter += 1
    let time = (shootingConfig.timeLimitShot - this.frameCounter) / fpsManager.fpsLimit
    if (time < 0) time = 0
    startPhaseState().startTimer = time

    // kontrolujeme ci sa ma uz skoncit na zaklade casoveho limitu
    if (this.frameCounter >= shootingConfig.timeLimitShot) {

      windState().showCountdown = false
      console.log('CASOVY LIMIT UPLYNUL')

      if (modes.isTournament()) {

        // zapiseme vysledok
        playersManager.setPlayerResults(0, 0, 0)
        playersManager.setStandings()
        endManager.resultsCount += 1

      }

      // musime si zistit, ci mame plne natiahnuty luk a ak ano, tak vystrelit
      if (disciplinePhasesManager.phaseAim.isActive) {

        // vystrelime
        disciplinePhasesManager.phaseAim.finishPhase(false)
        return true

      } else {

        if (!this.outOfTimeDelayActive) {

          if (
            disciplinePhasesManager.getActualPhase() === DisciplinePhases.draw &&
            (disciplinePhasesManager.phaseDraw as DrawPhaseWithHold).activeDrawForward
          ) {

            disciplinePhasesManager.phaseDraw.finishPhase(true)
            disciplinePhasesManager.startDisciplinePhase(DisciplinePhases.aim)
            return false

          }

          actionButtonState().showJoystick = false
          uiState().blueBoxTextType = BlueBoxTextType.hidden

          const showFirstLine = true
          const showSecondLine = false
          startPhaseStateManager.resetTextMessageFinishedEmits(showFirstLine, showSecondLine)

          textMessageState().$patch({
            showFirstLine: showFirstLine,
            showSecondLine: showSecondLine,
            firstLineText: 'text-out-of-time',
            firstLineTextType: 0,
            secondLineTextType: 2,
            secondLineLeftNumber: 0,
            showMessage: true,
            showType: 3
          })
          this.outOfTimeDelayActive = true
          playersManager.setStandings()
          miniTableState().$patch({
            rowsData: StateManager.getDataForMiniTable(),
            show: corePhasesManager.disciplineActualAttempt % corePhasesManager.provisionalResultsFrequency !== 0 &&
              !modes.isTutorial() && !modes.isTrainingMode()
          })

          trainingTasks.countArrowPoints({
            points: 0,
            countX: 0,
            count10: 0
          })
          gsap.to({}, {
            onComplete: () => {

              // ukoncime
              disciplinePhasesManager.finishActualPhase(true)
              disciplinePhasesManager.startDisciplinePhase(DisciplinePhases.finish)
              windState().$patch({
                showWindCenter: false,
                showCountdown: false,
                showWind: false
              })
              this.outOfTimeDelayEnded = true

              startPhaseStateManager.hideTextMessage(1)
              startPhaseStateManager.hideTextMessage(2)
              tutorialFlow.outOfTime()

            },
            duration: 1.5
          })

        }

        return this.outOfTimeDelayEnded


      }

    }

    return false

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.frameCounter = 0
    this.isActive = false
    this.outOfTimeDelayEnded = false
    this.outOfTimeDelayActive = false

  }

}

export const timeLimitManager = new TimeLimitManager()

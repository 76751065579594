<template>
  <div class="start-stats-box">
    <start-countdown-time
      v-if="windState.showCountdown"
      text="Time to start"
      :time="timer"
      :style="{position: 'absolute', left: '50%', top: '1%',
               margin: '0.25em 0px'}"
    />
    <wind-box
      v-if="windState.showWind"
      :wind-value="String(windState.value)"
      :rotate-value="rotateValue"
    />
    <wind-component
      v-if="windState.showWindCenter"
      :wind-value="String(windState.value)"
      :rotate-value="rotateValue"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  StartCountdownTime,
  WindBox,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'
import WindComponent from './WindComponent.vue'
import {
  windState,
  type StartPhaseState,
  startPhaseState,
} from '@/stores'

export default defineComponent({
  name: 'StartStatsBox',
  components: {
    StartCountdownTime,
    WindComponent,
    WindBox
  },
  mixins: [WindowAspect],
  data() {

    return {
      timer: 0,
      windState: windState(),
      startPhaseState: startPhaseState(),
    }

  },
  computed: {
    rotateValue() {

      return (Number(windState().direction) + 2) * 15 + 60

    }
  },
  watch: {

    startPhaseState: {
      handler(value: StartPhaseState) {

        if (Math.ceil(value.startTimer) !== this.timer) {

          this.timer = Math.ceil(value.startTimer)

        }

      },
      deep: true,
      immediate: true
    }

  }
})
</script>

<style lang="less" scoped>
  .start-stats-box {
    height: 100%;
    position: absolute;
    width: 100%;

    .wind-box {
      position: absolute;
      right: 1%;
      top: 17%;
      transform-origin: top right;
    }
  }
</style>

import type { TrainingDataFromResultsRequest } from '@powerplay/core-minigames'
import { defineStore } from 'pinia'

export interface TrainingResultsState {
  showResults: boolean,
  showTrainAgain: boolean,
  isDisabledPlayAgain: boolean,
  bestScore: number,
  dataSet: boolean,
  data: TrainingDataFromResultsRequest | unknown
}

const initialState = (): TrainingResultsState => ({
  showResults: false,
  showTrainAgain: true,
  isDisabledPlayAgain: false,
  bestScore: 0,
  dataSet: false,
  data: {
    stars: 0,
    score: {
      base: 0,
      total: 0,
      bonuses: 0
    },
    bonus: {
      benefits: 0,
      alchemy: 0,
      subscription: 0
    },
    attribute: {
      value_from: 0,
      value_next: 0,
      tp_from: 0,
      tp_new: 0,
      tp_milestone: 0
    },
    energy: {
      value_new_game: 0,
      available: 1
    },
    rewards: [
    ]
  }
})

export const trainingResultsState = defineStore('trainingResultsState', {
  state: initialState,
})

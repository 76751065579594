<template>
  <div>
    <div
      class="training-layout"
    >
      <training-left-top-box
        v-if="trainingState.showLeftBoxes"
        :text="$t('highScore')"
        :points="highScore"
        :tasks="trainingState.tasks"
        :max-t-p-length="maxTPLength"
      />
    </div>
    <training-blue-box
      v-if="trainingState.showNewHighScore"
      style="position: absolute"
      :points="String(trainingState.newHighScore)"
      :text="$t('newHighScore')"
      :style="{width: 100 / scaleCoef + '%'}"
    />
  </div>
</template>

<script lang="ts">
import {
  TrainingBlueBox,
  TrainingLeftTopBox,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'
import { trainingManager } from '@powerplay/core-minigames'
import { trainingState } from '@/stores'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    TrainingLeftTopBox,
    TrainingBlueBox
  },
  mixins: [WindowAspect],
  data() {

    return {
      trainingState: trainingState(),
    }

  },
  computed: {
    highScore(): string {

      console.log('high score?', trainingManager.bestScore, String(trainingManager.bestScore))
      return String(trainingManager.bestScore)

    },
    maxTPLength() {

      return trainingManager.getTpPerTask()?.toString().length

    }
  }
})

</script>

<style lang="less">
.training-layout {
    position: absolute;
    left: 1%;
    top: 30px;
    width: 100%;
    height: 100%;
}
.training-blue-box {
    position: fixed;
}
</style>

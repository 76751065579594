import { defineStore } from 'pinia'

export interface ReleasePhaseState {
  isActive: boolean,
  markPosition: number
}

const initialState = (): ReleasePhaseState => ({
  isActive: false,
  markPosition: 0
})

export const releasePhaseState = defineStore('releasePhaseState', {
  state: initialState,
})

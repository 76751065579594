<template>
  <section class="positioner-joystick">
    <movement-control
      v-if="actionButtonState.showJoystick"
      :style="shootPosition"
      :disabled="inputsState.isDisabled"
      class="pointer-events-auto joystick"
    />
  </section>
</template>

<script lang="ts">
import MovementControl from './MovementControl.vue'
import { WindowAspect } from '@powerplay/core-minigames-ui-ssm'
import { defineComponent } from 'vue'
import {
  actionButtonState,
  inputsState
} from '@/stores'

export default defineComponent({
  name: 'MobileInputs',
  components: {
    MovementControl,
  },
  mixins: [WindowAspect],
  data() {

    return {
      actionButtonState: actionButtonState(),
      inputsState: inputsState(),
    }

  },
  computed: {
    shootPosition() {

      return {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      }

    }
  }
})
</script>

<style lang="less">
.positioner-joystick {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  .flex {
    display: flex;
  }

  .justify-between {
    justify-content: space-between;
  }

  .relative {
    position: relative;
  }

  .pointer-events-auto {
    pointer-events: auto;
  }

  .buttons-position {
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
    height: 200px;
  }
  .joystick {
    img {
      opacity: 0
    }
  }
}
</style>

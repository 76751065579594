import {
  trainingConfig,
  windConfig
} from '@/app/config'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  Direction,
  DisciplinePhases
} from '@/app/types'
import {
  gauss,
  playersManager,
  modes,
  corePhasesManager
} from '@powerplay/core-minigames'
import { aimingDirectionManager } from '@/app/phases/AimPhase/AimingDirectionManager'
import { opponent } from './opponent/Opponent'
import { windState } from '@/stores'

/**
 * Vietor
 */
export class Wind {

  /** Vygenerovana hodnota vetra */
  private generatedValue = 0

  /** Hodnota vetra */
  private actualValue = 99999

  /** Vygenerovany smer vetra */
  private generatedDirection = Direction.N

  /** Smer vetra */
  private actualDirection = Direction.N

  /** Pocitadlo frameov */
  private framesCounter = 0

  /** ci je vietor aktivny (napr v tutoriali) */
  public isActive = false

  /** ci sa vietor meni (napr v treningu) */
  public isChanging = false

  /**
   * Vratenie hodnoty vetra
   * @returns Vietor
   */
  public getValue(): number {

    return this.actualValue

  }

  /**
   * Nastavenie hodnoty vetra
   */
  private setFirstTime(): void {

    const { center, difference, min, max } = this.getMinMaxPower()

    this.actualValue = gauss.getValue(center, difference, min, max)
    console.log('Vygenerovana hodnota gaussa: ', this.actualValue)

    // pre istotu dame limity
    if (this.actualValue > windConfig.maxValue) this.actualValue = windConfig.maxValue
    if (this.actualValue < windConfig.minValue) this.actualValue = windConfig.minValue

    this.actualDirection = Math.floor(Math.random() * 24) // je 24 snmerov

    const { active, direction, value } = windConfig.debug
    if (active) {

      console.log('davame DEBUG hodnoty vetra!')
      this.actualValue = value
      this.actualDirection = direction

    }
    if (modes.isTrainingMode()) {

      this.actualValue = trainingConfig.wind[corePhasesManager.disciplineActualAttempt - 1].value
      console.warn(
        corePhasesManager.disciplineActualAttempt - 1,
        trainingConfig.wind[corePhasesManager.disciplineActualAttempt - 1].value
      )

    }

    this.generatedValue = this.actualValue
    this.generatedDirection = this.actualDirection

    console.log(
      'vygenerovany vietor je ', this.generatedValue, Direction[this.generatedDirection],
      `resp. ${this.generatedDirection * 15 } stupnov`
    )
    windState().$patch({
      value: this.actualValue,
      direction: this.actualDirection
    })

  }

  /**
   * Vypocitanie min a max power pre vietor
   * @param min - minimalna hodnota
   * @param max - maximalna hodnota
   * @param diff - odchylka
   * @param center - stredna hodnota
   * @returns min max power
   */
  private getMinMaxPower(): {min: number, max: number, difference: number, center: number} {

    if (modes.isTournament()) {

      return {
        min: 1,
        max: 12,
        difference: 3,
        center: 6
      }

    }

    if (opponent.uuid === '') {

      return {
        min: 1,
        max: 5,
        difference: 2,
        center: 3
      }

    }

    const playerStrength = playersManager.getPlayer().attribute.total
    const sortedPlayers = playersManager.getPlayersSortedByAttribute()
    const topOpponentStrength = sortedPlayers[0].playable ?
      sortedPlayers[1].attribute.total :
      sortedPlayers[0].attribute.total

    console.log('Top opponent strngth: ', topOpponentStrength)
    const min = 1
    let max, difference, center

    const strengthDiff = playerStrength - topOpponentStrength

    if (playerStrength <= 100) {

      max = 5
      difference = 2

      if (strengthDiff > 30) {

        center = 1

      } else if (strengthDiff > 15) {

        center = 2

      } else if (strengthDiff > -15) {

        center = 3

      } else if (strengthDiff > -30) {

        center = 4

      } else {

        center = 5

      }

    } else {

      max = 9
      difference = 3

      if (strengthDiff > 60) {

        center = 2

      } else if (strengthDiff > 30) {

        center = 3

      } else if (strengthDiff > 15) {

        center = 4

      } else if (strengthDiff > -15) {

        center = 5

      } else if (strengthDiff > -30) {

        center = 6

      } else if (strengthDiff > -60) {

        center = 7
        max = 10

      } else {

        center = 8
        max = 12

      }

    }

    console.log(`Gaus min: ${min}, max: ${max}, diff: ${difference}, center: ${center}`)
    return {
      min,
      max,
      difference,
      center
    }

  }

  /**
   * Zmena vetra
   */
  private change(): void {

    const { active } = windConfig.debug
    if (active || !this.isChanging) return
    const { chanceToChangeDirection, chanceToChangeValue } = windConfig.change
    const dirConst = Math.round(Math.random()) === 1 ? -1 : 1 // predpriprava

    // ci mame menit smer
    if (Math.random() < chanceToChangeDirection) {

      this.actualDirection += dirConst

      // ak vyjdeme z povolenych hodnot, tak musime dat na dalsiu pripustnu hodnotu v poradi
      if (this.actualDirection > this.generatedDirection + 1) this.actualDirection = this.generatedDirection + 1
      if (this.actualDirection < this.generatedDirection - 1) this.actualDirection = this.generatedDirection - 1

      // zistenie kolko mame smerov vetra
      const dirLength = Object.keys(Direction).filter((v) => isNaN(Number(v))).length - 1
      // wrap ak sa dostaneme mimo nasich hodnot smeru vetra
      if (this.actualDirection > dirLength) this.actualDirection = 0
      if (this.actualDirection < 0) this.actualDirection = dirLength

    }

    // ci mame menit hodnotu
    if (Math.random() < chanceToChangeValue) {

      // zmenime smer
      this.actualValue += dirConst

      // ak vyjdeme z povolenych hodnot, tak musime dat najblizsiu hodnotu
      if (this.actualValue > this.generatedValue + 1) this.actualValue = this.generatedValue + 1
      if (this.actualValue < this.generatedValue - 1) this.actualValue = this.generatedValue - 1

    }

    console.log('Bola zmena vetra:', this.actualValue, Direction[this.actualDirection])
    windState().$patch({
      value: this.actualValue,
      direction: this.actualDirection
    })

  }

  /**
   * Nastavenie noveho bodu pomocou offsetov aktualneho vetra
   * @param oldPoint - Stary bod
   * @returns Novy bod
   */
  public getNewPoint(oldPoint: THREE.Vector3): THREE.Vector3 {

    const point: THREE.Vector3 = oldPoint.clone()
    if (!this.isActive) return point

    const step = aimingDirectionManager.directionStep[this.actualDirection]
    const offsetCoef = windConfig.offsetValue * this.actualValue

    point.x -= step.x * offsetCoef
    point.y += step.y * offsetCoef

    return point

  }

  /**
   * Aktualizovanie veci kazdy frame
   */
  public update(): void {

    // aktualizujeme iba pred vystrelenim
    if (
      disciplinePhasesManager.actualPhase > DisciplinePhases.aim ||
      disciplinePhasesManager.actualPhase < DisciplinePhases.draw
    ) return

    this.framesCounter += 1

    // musime prvykrat nastavit hodnotu vetra
    if (this.actualValue === 99999) this.setFirstTime()

    // raz za x frameov menime vietor
    if (this.framesCounter % windConfig.change.frames === 0) this.change()

  }

  /**
   * Resetovanie veci
   */
  public reset(): void {

    this.framesCounter = 0
    this.actualValue = 99999
    this.actualDirection = Direction.N

  }

}

export const wind = new Wind()
